// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  APP_URL: 'farm-first.com',
  PARSE_APP_ID: 'bHvyFEl9gY56UFEgjtv2L46O30YxdvihHGtlqp6C',
  PARSE_JS_KEY: 'TgSVcNHK9E8hZUORE0rLGVnGrtqrMwzAofbXHQjx',
  PARSE_REST_API_KEY: 'LJUWxozuvvBRPc0ZZHiqkQl5c6Nd8XJ69aTSl4Pg',
  PARSE_MASTER_KEY:'Et8sXVrHdUsjlIFDP102yAjJL3vLuPxX47JOVAcD',
  serverURL: 'https://parseapi.back4app.com',
  FACEBOOK_API_URL: 'https://graph.facebook.com',
  FACEBOOK_APP_ID: '607410872729227',
  FACEBOOK_CLIENT_SECRET: 'e71cdafdd3a52fc5d40a462c71f95c2a',
  FACEBOOK_APP_SECRET: '913ecad1fb9f08faf10ccfed099cfc9d',
  FACEBOOK_REDIRECT_URL: 'https://farm-first.com/account/oauth/process',
  RAZORPAY_API_URL: 'https://api.razorpay.com/v1',
  RAZORPAY_API_KEY: 'rzp_live_6wedh3WcbapsTU',
  MIXPANEL_TOKEN: 'b1fbf444c9867835dabb6120d3c7302a',
  MIXPANEL_TRACK_PREFIX: 'WEB: ',
  FIREBASE: {
    apiKey: "AIzaSyDoT4AWfgUcffoiunPOiPmv574JydK5y_I",
    authDomain: "project-1578038047940958237.firebaseapp.com",
    databaseURL: "https://project-1578038047940958237.firebaseio.com",
    projectId: "project-1578038047940958237",
    storageBucket: "project-1578038047940958237.appspot.com",
    messagingSenderId: "51180419220",
    appId: "1:51180419220:web:09dc80224b1143c82edc81",
    measurementId: "G-0GK6Z29JMZ"
  },
  FIRESTORE_ERROR_COLLECTION: 'ff_web_error_log',
  FIRESTORE_ERROR_PREFIX: '',
  baseHref: '/',
  COOKIE_SECURE: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
